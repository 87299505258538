import React, { createContext, useState } from "react";

const initialValues = {
  associationName: "",
  user: "",
  members: [
    {
      role: "President",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Vice President",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Treasurer",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Secretary",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Member at Large 1",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Member at Large 2",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
    {
      role: "Member at Large 3",
      name: "",
      email: "",
      phone: "",
      term: "",
    },
  ],
  committees: true,
  committeeNames: "",
  committeeMembers: "",
  communicateWithPresident: true,
  communicateWithTreasurer: true,
};

export const BoardMemberContext = createContext({
  formValues: {},
  handleChange: () => {},
  updateMember: () => {},
  page: 0,
  setPage: () => {},
  updateBoolean: () => {},
  setFormValues: () => {},
});

function BoardMemberContextWrapper({ children }) {
  const [formValues, setFormValues] = useState(initialValues);
  const [page, setPage] = useState(0);

  const updateBoolean = (field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateMember = (role, field, value) => {
    setFormValues((prev) => ({
      ...prev,
      members: prev.members.map((m) =>
        m.role === role
          ? {
              ...m,
              [field]: value,
            }
          : m
      ),
    }));
  };

  const handleChange = (e) => {
    console.log("handleChange");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <BoardMemberContext.Provider
      value={{
        formValues,
        handleChange,
        updateMember,
        page,
        setPage,
        updateBoolean,
        setFormValues,
      }}
    >
      {children}
    </BoardMemberContext.Provider>
  );
}

export default BoardMemberContextWrapper;
